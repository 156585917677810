import styled from "styled-components";

export const ItemWithDateContainer = styled.div`
  align-items: flex-start;
  display: flex;
  gap: 10px;
  justify-content: space-between;

  & > *:last-child {
    max-width: 80px;
    min-width: 80px;
  }
`;

export const EventsWrapper = styled.div`
  padding-top: 25px;
`;

export const EventsHeader = styled.div`
  border-left: 3px solid #babec3;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 10px;
  padding-left: 23px;

  .Polaris-Text--root {
    border-bottom: 1px solid #e1e3e5;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
`;

export const EventsList = styled.ul`
  list-style: none;
  padding: 0;

  & li:nth-last-child(2) {
    padding-bottom: 20px;
  }
`;

export const StartOfEventsLi = styled.li`
  margin-left: 10px;
  padding: 0 0 10px 20px;
  position: relative;
  top: -10px;

  &::before {
    background: #8c9196;
    border-radius: 20%;
    content: "";
    height: 3px;
    left: -6px;
    position: absolute;
    top: 10px;
    width: 15px;
  }
`;

export const StyledDate = styled.div`
  border-left: 3px solid #babec3;
  margin-left: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
`;

export const StyledEvent = styled.li`
  border-left: 3px solid #babec3;
  list-style: none;
  margin-left: 10px;
  padding: 0;
  padding-bottom: 10px;
  padding-left: 20px;
  position: relative;

  &::before {
    background: #8c9196;
    border: 2px solid #f6f6f7;
    border-radius: 50%;
    content: "";
    height: 15px;
    left: -9px;
    position: absolute;
    top: 0;
    width: 15px;
  }
`;

export const StyledComment = styled(StyledEvent)`
  &::before {
    top: 40%;
  }
`;

export const PinnedComment = styled(StyledComment)`
  .Polaris-LegacyCard {
    background-color: #fff5ea;
  }
`;

export const CommentForRemovalContainer = styled.div`
  .Polaris-LegacyCard {
    background-color: #fafbfb;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;
