import {
  Avatar,
  Button,
  Icon,
  LegacyCard,
  LegacyStack,
  Modal,
  Scrollable,
  Spinner,
  Text,
  TextField,
  Tooltip,
} from "@shopify/polaris";
import { DeleteMinor, InfoMinor, PinMinor } from "@shopify/polaris-icons";
import { SuperuserBadge } from "@smartrr/shared/components/SuperuserBadge";
import { ISODateString } from "@smartrr/shared/entities/ISODateString";
import {
  ISubscriptionEvent,
  ISubscriptionEventWithPurchaseStateInformation,
  SubscriptionEventTypeEnum,
} from "@smartrr/shared/entities/SubscriptionEvent";
import { toViewDate } from "@smartrr/shared/utils/renderViewDate";
import { groupBy } from "lodash";
import { DateTime } from "luxon";
import React, { useCallback, useMemo, useState } from "react";

import { useIsSuperUserSelector } from "@smartrr/vendor-portal/src/app/_state/reducers/auth";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";
import { useVendorPortalVariantToPurchasableMap } from "@vendor-app/app/_state/reducers/purchasables";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

import {
  ButtonContainer,
  CommentForRemovalContainer,
  EventsHeader,
  EventsList,
  EventsWrapper,
  ItemWithDateContainer,
  PinnedComment,
  ShopifyEvent,
  StartOfEventsLi,
  StyledComment,
  StyledDate,
  StyledEvent,
  formatEventDescription,
  isPinnedComment,
  isShopifyComment,
} from "./libs";

interface Props {
  subscriptionEvents: ISubscriptionEventWithPurchaseStateInformation[];
  loading: boolean;
  shopifyEvents?: ShopifyEvent[];
  hideComments?: boolean;

  addComment?: (text: string, authorName: string) => void;
  pinComment?: (eventId: string) => void;
  removeComment?: (eventId: string) => void;
}

export const Events = ({
  subscriptionEvents,
  shopifyEvents,
  loading,
  hideComments = false,
  addComment,
  pinComment,
  removeComment,
}: Props) => {
  const currentUser = useSmartrrVendorSelector(state => state.auth.user);
  const vntToPurchasableMap = useVendorPortalVariantToPurchasableMap();
  const { addToast } = useToast();
  const isSuperUser = useIsSuperUserSelector();
  const [comment, setComment] = useState<string | null>(null);
  const [commentSelectedForRemoval, setCommentSelectedForRemoval] = useState<ISubscriptionEvent | null>(null);
  const [openRemoveCommentModal, setOpenRemoveCommentModal] = useState(false);

  const handleChangeComment = (comment: string) => {
    setComment(comment);
  };

  const handleAddComment = useCallback(() => {
    if (!comment) {
      addToast("Please add a comment");
      return;
    }
    setComment(null);
    addComment?.(comment, `${currentUser?.originalFirstName} ${currentUser?.originalLastName}`);
  }, [comment]);

  const organization = useActiveOrganizationSelector();
  const formatDateTime = (date: ISODateString) => {
    const day = ISODateString.fromString(date).setZone(organization?.billingTimezone);
    const formattedDay = day.toLocaleString(DateTime.DATE_FULL);
    const time = day.toLocaleString({
      hour: "2-digit",
      minute: "2-digit",
    });
    return [formattedDay, time];
  };

  const eventsGroupedByDate = useMemo(() => {
    return groupBy(
      [...subscriptionEvents.filter(event => !isPinnedComment(event)), ...(shopifyEvents ?? [])],
      event => {
        const date = new Date(isShopifyComment(event) ? event.createdAt : event.eventDate);
        return toViewDate(ISODateString.fromString(date).toJSDate(), {
          day: "numeric",
          month: "long",
          year: "numeric",
        });
      }
    );
  }, [subscriptionEvents, shopifyEvents]);

  const renderEvent = useCallback((event: ISubscriptionEvent | ShopifyEvent) => {
    if (isShopifyComment(event)) {
      const [, time] = formatDateTime(event.createdAt);

      return (
        <StyledEvent key={event.id}>
          <ItemWithDateContainer>
            <span dangerouslySetInnerHTML={{ __html: formatEventDescription(event, vntToPurchasableMap) }} />
            <Text variant="bodyMd" as="span" color="subdued" id="subscription-events__event-section__time">
              {time}
            </Text>
          </ItemWithDateContainer>
        </StyledEvent>
      );
    }

    const [day, time] = formatDateTime(event.eventDate);
    const isPinned = isPinnedComment(event);

    if (event.eventType === "ADMIN_ADDED_COMMENT") {
      const CommentComponent = isPinned ? PinnedComment : StyledComment;

      const initials = event.eventData?.comment?.authorName
        .split(" ")
        .map(name => name[0])
        .join("");

      return (
        <CommentComponent key={event.id} id="subscription-events__comment-section">
          <LegacyCard sectioned>
            <LegacyStack vertical>
              <LegacyStack distribution="equalSpacing">
                <Text
                  variant="bodyMd"
                  as="span"
                  color="subdued"
                  fontWeight="semibold"
                  id="subscription-events__comment-section__day"
                >
                  {day.toLocaleUpperCase()}
                </Text>
                <ButtonContainer>
                  <div
                    onClick={() => pinComment?.(event.id)}
                    style={{ cursor: "pointer" }}
                    id="subscription-events__comment-section__pinned-comment"
                  >
                    <Icon source={PinMinor} color={isPinned ? "warning" : "subdued"} />
                  </div>
                  <Button
                    plain
                    icon={DeleteMinor}
                    onClick={() => {
                      setCommentSelectedForRemoval(event);
                      setOpenRemoveCommentModal(true);
                    }}
                  />
                </ButtonContainer>
              </LegacyStack>
              <ItemWithDateContainer>
                <Text variant="bodyMd" as="span" id="subscription-events__comment-section__comment-text">
                  &quot;{event.eventData?.comment?.text}&quot;
                </Text>
                <Text
                  variant="bodyMd"
                  as="span"
                  color="subdued"
                  id="subscription-events__comment-section__comment-day"
                >
                  {time.toLocaleUpperCase()}
                </Text>
              </ItemWithDateContainer>
              <LegacyStack spacing="extraTight" alignment="center">
                <Avatar size="extraSmall" name={event.eventData?.comment?.authorName} initials={initials} />
                {isPinned ? (
                  <Text
                    variant="bodySm"
                    as="span"
                    color="warning"
                    id="subscription-events__comment-section__pinned-by"
                  >
                    Pinned comment by {event.eventData?.comment?.authorName}
                  </Text>
                ) : (
                  <Text
                    variant="bodySm"
                    as="span"
                    color="subdued"
                    id="subscription-events__comment-section__commented-by"
                  >
                    Comment by {event.eventData?.comment?.authorName}
                  </Text>
                )}
              </LegacyStack>
            </LegacyStack>
          </LegacyCard>
        </CommentComponent>
      );
    }

    if (event.eventType === SubscriptionEventTypeEnum.SUPER_USER_LOGGEDIN_AS_CUSTOMER) {
      if (isSuperUser) {
        <StyledEvent key={event.id}>
          <ItemWithDateContainer>
            <Text variant="bodyMd" as="span" id="subscription-events__event-section__text">
              <SuperuserBadge />
              {formatEventDescription(event, vntToPurchasableMap)}
            </Text>
            <Text variant="bodyMd" as="span" color="subdued" id="subscription-events__event-section__time">
              {time}
            </Text>
          </ItemWithDateContainer>
        </StyledEvent>;
      } else {
        return <React.Fragment />;
      }
    }
    return (
      <StyledEvent key={event.id}>
        <ItemWithDateContainer>
          <Text variant="bodyMd" as="span" id="subscription-events__event-section__text">
            {formatEventDescription(event, vntToPurchasableMap)}
          </Text>
          <Text variant="bodyMd" as="span" color="subdued" id="subscription-events__event-section__time">
            {time}
          </Text>
        </ItemWithDateContainer>
      </StyledEvent>
    );
  }, []);

  const renderCommentForRemoval = useCallback((event: ISubscriptionEvent) => {
    const [day, time] = formatDateTime(event.eventDate);

    const initials = event.eventData?.comment?.authorName
      .split(" ")
      .map(name => name[0])
      .join("");

    return (
      <CommentForRemovalContainer>
        <LegacyCard sectioned>
          <LegacyStack vertical>
            <LegacyStack distribution="equalSpacing">
              <Text
                variant="bodyMd"
                as="span"
                color="subdued"
                fontWeight="semibold"
                id="subscription-events__remove-comment-section__day"
              >
                {day.toLocaleUpperCase()}
              </Text>
            </LegacyStack>
            <ItemWithDateContainer>
              <Text variant="bodyMd" as="span" id="subscription-events__remove-comment-section__text">
                &quot;{event.eventData?.comment?.text}&quot;
              </Text>
              <Text
                variant="bodyMd"
                as="span"
                color="subdued"
                id="subscription-events__remove-comment-section__time"
              >
                {time.toLocaleUpperCase()}
              </Text>
            </ItemWithDateContainer>
            <LegacyStack spacing="extraTight" alignment="center">
              <Avatar size="extraSmall" name={event.eventData?.comment?.authorName} initials={initials} />
              <Text variant="bodySm" as="span" id="subscription-events__remove-comment-section__comment-by">
                Comment by {event.eventData?.comment?.authorName}
              </Text>
            </LegacyStack>
          </LegacyStack>
        </LegacyCard>
      </CommentForRemovalContainer>
    );
  }, []);

  return (
    <EventsWrapper>
      <EventsHeader>
        <Text variant="headingMd" as="h4" fontWeight="bold">
          Event log
        </Text>
        {hideComments ? null : (
          <TextField
            id="subscription-events__add-comment-section__comment-textfield"
            label=""
            labelHidden
            autoComplete="off"
            placeholder="Leave an internal comment on this subscription..."
            value={comment ?? ""}
            onChange={handleChangeComment}
            connectedRight={<Button onClick={() => handleAddComment()}>Post</Button>}
          />
        )}
        <div />
      </EventsHeader>
      <Scrollable style={{ height: "340px" }}>
        {loading ? (
          <LegacyStack alignment="center" distribution="center">
            <Spinner />
          </LegacyStack>
        ) : subscriptionEvents.length > 0 ? (
          <EventsList>
            {subscriptionEvents
              .filter(a => {
                return isPinnedComment(a);
              })
              .map(event => renderEvent(event))}
            {Object.keys(eventsGroupedByDate).map((key: string) => {
              return (
                <React.Fragment key={key}>
                  <StyledDate>
                    <Text
                      variant="bodyMd"
                      as="p"
                      color="subdued"
                      fontWeight="semibold"
                      id="subscription-events__add-comment-section__day-for-group"
                    >
                      {key.toLocaleUpperCase()}
                    </Text>
                  </StyledDate>
                  {eventsGroupedByDate[key].map(renderEvent)}
                </React.Fragment>
              );
            })}
            <StartOfEventsLi>
              <Tooltip
                dismissOnMouseOut
                preferredPosition="above"
                content="Only events created after February 17th, 2022 will be present in this timeline."
              >
                <LegacyStack spacing="tight">
                  <Text as="p" variant="bodyMd">
                    Start of events
                  </Text>
                  <Icon source={InfoMinor} color="base" />
                </LegacyStack>
              </Tooltip>
            </StartOfEventsLi>
          </EventsList>
        ) : (
          <LegacyStack vertical distribution="center" alignment="center">
            <Text variant="headingMd" as="h4" fontWeight="bold">
              No events yet
            </Text>
          </LegacyStack>
        )}
      </Scrollable>
      {commentSelectedForRemoval == null ? null : (
        <Modal
          sectioned
          title="Delete comment?"
          open={openRemoveCommentModal}
          onClose={() => setOpenRemoveCommentModal(false)}
          primaryAction={{
            id: "subscription-events__remove-comment-modal__confirm-btn",
            content: "Confirm delete",
            onAction() {
              removeComment?.(commentSelectedForRemoval.id);
              setOpenRemoveCommentModal(false);
            },
            destructive: true,
            loading,
          }}
          secondaryActions={[
            {
              id: "subscription-events__remove-comment-modal__cancel-btn",
              content: "Cancel",
              onAction() {
                setOpenRemoveCommentModal(false);
                setCommentSelectedForRemoval(null);
              },
              loading,
            },
          ]}
        >
          {renderCommentForRemoval(commentSelectedForRemoval)}
        </Modal>
      )}
    </EventsWrapper>
  );
};
