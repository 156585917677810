import { ButtonGroup, LegacyCard, TextField, useIndexResourceState } from "@shopify/polaris";
import { Box } from "@smartrr/shared/components/primitives";
import { CurrencyCode } from "@smartrr/shared/currencyCode";
import { IBillWithPurchStAndCustomerRelationshipPaginatedResponse } from "@smartrr/shared/entities/Billing";
import { IOrganization } from "@smartrr/shared/entities/Organization";
import { captureException } from "@smartrr/shared/utils/captureException";
import { shopifyGidToNumber } from "@smartrr/shared/utils/ensureShopifyGid";
import React, { useCallback, useEffect, useState } from "react";

import { useFilterUpdate } from "@vendor-app/app/_sharedComponents/TableSearch/libs";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import {
  SelectionType,
  usePolarisTypedTable,
} from "@vendor-app/app/_sharedComponents/TypedTable/usePolarisTypedTable";
import { useTableHandlers } from "@vendor-app/app/_sharedComponents/TypedTable/useTableHandlers";
import {
  getSubscriptionBillsByIdOrShopifyIdAPI,
  putBulkCancelBillsWithQuery,
  putBulkRetryBillsWithQuery,
} from "@vendor-app/app/_state/actionCreators/bill";
import {
  CPSTransactionHistoryTableColumnKeyType,
  CPSTransactionHistoryTableColumnType,
  ValueSelectorForTransactionHistoryFilter,
  parseBillsForIndexTable,
} from "@vendor-app/app/AdminRoute/AdminBillsRoute/libs";
import {
  HeaderContainer,
  PaginationContainer,
  checkIfBillCanBeCanceled,
  checkIfBillCanBeRetried,
} from "@vendor-app/app/AdminRoute/AdminSubscriptionDetailsRoute/libs";
import { isRowSelected } from "@vendor-app/utils/isIndexTableRowSelected";

export const cpsTransactionHistoryColumns: CPSTransactionHistoryTableColumnType = {
  id: {
    name: "Transaction ID",
    paginatedValue: "id",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  createdDate: {
    name: "Initial bill attempt",
    paginatedValue: "createdDate",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  updatedDate: {
    name: "Last update time",
    paginatedValue: "updatedDate",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  status: {
    name: "Status",
    paginatedValue: "status",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  estimatedTotalNet: {
    name: "Total",
    paginatedValue: "estimatedTotalNet",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  totalInCustomerCurrency: {
    name: "Cust. currency",
    paginatedValue: "billAmount",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  retryCount: {
    name: "Retry count",
    paginatedValue: "retryCount",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  error: {
    name: "Failure reason",
    paginatedValue: "error",
    filtering: true,
    sorting: true,
    disabled: false,
  },
};

interface Props {
  subscriptionShopifyId: string;
  subscriptionId: string;
  activeOrg: IOrganization | null;
  customerCurrency: CurrencyCode | undefined;
}

export const TransactionHistory = ({
  subscriptionShopifyId,
  subscriptionId,
  activeOrg,
  customerCurrency,
}: Props) => {
  const { addToast } = useToast();

  const { Table, Pagination, Filter, Sorting, Columns } =
    usePolarisTypedTable<CPSTransactionHistoryTableColumnKeyType>();
  const [tableProps, tableHandlers] = useTableHandlers("createdDate", "DESC", undefined, 5, "createdDate");

  const [paginatedBills, setPaginatedBills] = useState<IBillWithPurchStAndCustomerRelationshipPaginatedResponse>({
    data: [],
    pageNumber: tableProps.pageNumber,
    pageSize: tableProps.pageSize,
    totalCount: 0,
    totalPages: 0,
  });
  const [isLoading, setLoading] = useState(false);

  const [cpsTransactionHistoryColumnsState, setCPSTransactionHistoryColumnsState] =
    useState<CPSTransactionHistoryTableColumnType>(cpsTransactionHistoryColumns);

  const { data: bills, totalPages, totalCount, pageSize } = paginatedBills;

  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(
    paginatedBills.data.map(bill => ({ id: String(bill.id) }))
  );

  const [debouncedUpdate] = useFilterUpdate(
    ({ queryParams }) => fetchTransactions(queryParams),
    tableHandlers.setPageNumber
  );

  const fetchTransactions = async (queryParams: any) => {
    setLoading(true);
    try {
      const res = await getSubscriptionBillsByIdOrShopifyIdAPI({
        shopifySubscriptionId: String(shopifyGidToNumber(subscriptionShopifyId)),
        queryParams,
      });
      if (res.type === "success" && res.body) {
        setPaginatedBills(res.body);
      } else {
        addToast("Couldn't load the transaction history");
      }
    } catch (error) {
      captureException("Couldn't load the transaction history", error);
      addToast("Couldn't load the transaction history");
    }
    setLoading(false);
  };

  const retryBills = useCallback(
    async (ids: string[]) => {
      try {
        const paginatedBillsRes = await putBulkRetryBillsWithQuery({ ids, tableProps, cpsId: subscriptionId });
        if (paginatedBillsRes.type === "success") {
          addToast("Bill(s) retried");
          setPaginatedBills(paginatedBillsRes.body);
        } else {
          addToast("Error retrying bill(s)");
        }
      } catch (error) {
        captureException("Error retrying bill(s)", error);
        addToast("Error retrying bill(s)");
      }
    },
    [subscriptionId, tableProps]
  );

  const cancelBills = useCallback(
    async (ids: string[]) => {
      try {
        const paginatedBillsRes = await putBulkCancelBillsWithQuery({ ids, tableProps, cpsId: subscriptionId });
        if (paginatedBillsRes.type === "success") {
          addToast("Retry canceled");
          setPaginatedBills(paginatedBillsRes.body);
        } else {
          addToast("Error canceling bill(s)");
        }
      } catch (error) {
        captureException("Error canceling bill(s)", error);
        addToast("Error canceling bill(s)");
      }
    },
    [subscriptionId, tableProps]
  );

  const promotedBulkActions = [
    checkIfBillCanBeRetried(bills, selectedResources)
      ? {
          content: "Retry",
          onAction() {
            retryBills(
              bills.filter(bill => selectedResources.includes(String(bill.id))).map(bill => bill.uniqueId)
            );
            handleSelectionChange(SelectionType.All, false);
          },
        }
      : null,
    checkIfBillCanBeCanceled(bills, selectedResources)
      ? {
          content: "Cancel retry",
          onAction() {
            cancelBills(
              bills.filter(bill => selectedResources.includes(String(bill.id))).map(bill => bill.uniqueId)
            );
            handleSelectionChange(SelectionType.All, false);
          },
        }
      : null,
  ];

  useEffect(() => {
    const { orderByField, orderByValue, pageNumber, pageSize } = tableProps;
    debouncedUpdate(pageNumber, pageSize, orderByField, orderByValue, false, tableProps.filter);
  }, [
    tableProps.pageNumber,
    tableProps.pageSize,
    tableProps.orderByField,
    tableProps.orderByValue,
    tableHandlers,
  ]);

  //fetching by filter gets its own useEffect,
  //because when filter updates the page should be reset to 0
  useEffect(() => {
    const { orderByField, orderByValue, pageNumber, pageSize } = tableProps;
    debouncedUpdate(pageNumber, pageSize, orderByField, orderByValue, true, tableProps.filter);
  }, [tableProps.filter, tableHandlers]);

  return (
    <React.Fragment>
      <LegacyCard.Section flush>
        <HeaderContainer>
          <div style={{ flex: 0.7 }}>
            <TextField
              id="subscription-details__transaction-history__show-next-textfield"
              autoComplete="off"
              label=""
              labelHidden
              min={1}
              type="number"
              inputMode="numeric"
              connectedLeft={
                <Box style={{ height: "100%" }} alignItems="center" pr={1}>
                  Show past
                </Box>
              }
              value={"" + tableProps.pageSize}
              onChange={value => tableHandlers.setPageSize(Number(value))}
            />
          </div>

          <ButtonGroup segmented>
            <Filter
              columns={cpsTransactionHistoryColumns}
              filter={tableProps.filter}
              setFilter={tableHandlers.setFilter}
              filterComponent={(field, value, handleSetFilter) => (
                <ValueSelectorForTransactionHistoryFilter
                  field={field}
                  value={value}
                  setValue={newValue => handleSetFilter("update", field, newValue)}
                  currency={activeOrg?.shopifyStoreData?.currency}
                  customerCurrency={customerCurrency}
                />
              )}
            />

            <Columns
              columns={cpsTransactionHistoryColumnsState}
              addToast={addToast}
              setDisabledColumns={setCPSTransactionHistoryColumnsState}
            />

            <Sorting
              columns={cpsTransactionHistoryColumnsState}
              orderByField={tableProps.orderByField}
              orderByValue={tableProps.orderByValue}
              setOrderByField={tableHandlers.setOrderByField}
              setOrderByValue={tableHandlers.setOrderByValue}
            />
          </ButtonGroup>
        </HeaderContainer>
        <Table
          selectable
          data={parseBillsForIndexTable(bills, activeOrg)}
          loading={isLoading}
          resourceName={{
            singular: "transaction",
            plural: "transactions",
          }}
          columns={cpsTransactionHistoryColumnsState}
          itemCount={totalCount < pageSize ? totalCount : pageSize}
          sortable={Object.values(cpsTransactionHistoryColumnsState)
            .filter(v => !v.disabled)
            .map(v => v.sorting)}
          sortColumnIndex={Object.entries(cpsTransactionHistoryColumnsState).findIndex(
            ([key, value]) => value.paginatedValue === tableProps.orderByField && !value.disabled
          )}
          selectedItemsCount={allResourcesSelected ? "All" : selectedResources.length}
          sortDirection={tableProps.orderByValue === "ASC" ? "ascending" : "descending"}
          promotedBulkActions={promotedBulkActions.filter((x): x is any => x != null)}
          emptyStateText="No transaction history"
          onSort={(headingIndex: number, direction: "ascending" | "descending") => {
            tableHandlers.setOrderByField(
              Object.values(cpsTransactionHistoryColumnsState).filter(value => !value.disabled)[headingIndex]
                .paginatedValue
            );
            tableHandlers.setOrderByValue(direction === "ascending" ? "ASC" : "DESC");
          }}
          onSelectionChange={(selectionType, toggleType, selection) => {
            handleSelectionChange(selectionType, toggleType, selection);
          }}
          isRowSelected={id => isRowSelected(id, selectedResources)}
        />
      </LegacyCard.Section>
      <LegacyCard.Section subdued>
        <PaginationContainer id="subscription-details__transaction-history__pagination-section">
          <Pagination
            hasNext={totalPages > tableProps.pageNumber}
            hasPrevious={tableProps.pageNumber != 1}
            label={`Showing ${tableProps.pageNumber} of ${totalPages}`}
            onNext={() => tableHandlers.setPageNumber(p => p + 1)}
            onPrevious={() => tableHandlers.setPageNumber(p => p - 1)}
          />
        </PaginationContainer>
      </LegacyCard.Section>
    </React.Fragment>
  );
};
