import { DEFAULT_VARIANT_TITLE } from "@smartrr/shared/constants";
import { IMailingAddress } from "@smartrr/shared/entities/MailingAddress";
import { IPurchaseState } from "@smartrr/shared/entities/PurchaseState";
import {
  ISubscriptionEvent,
  ISubscriptionEventCreatorData,
  ISubscriptionEventWithPurchaseStateInformation,
  SubscriptionEventCreatorEnum,
  SubscriptionEventTypeEnum,
} from "@smartrr/shared/entities/SubscriptionEvent";
import { shopifyGidToNumber } from "@smartrr/shared/utils/ensureShopifyGid";
import { MappedPurchasableVariant } from "@smartrr/shared/utils/useVariantToPurchasableMap";

import { formatDateTime } from "@vendor-app/app/AdminRoute/AdminSuperUserRoute/utils";

import { ShopifyEvent } from "../types";
import { formatMoneyWithoutPrecisionConversion } from "@smartrr/shared/utils/formatMoney";

export const isPinnedComment = (x: ISubscriptionEvent) => x.eventData?.comment?.isPinned;
export const isShopifyComment = (x: any): x is ShopifyEvent =>
  x.hasOwnProperty("__typename") && (x.__typename === "BasicEvent" || x.__typename === "CommentEvent");

export const formatCreator = (
  createdBy: SubscriptionEventCreatorEnum,
  creatorData: ISubscriptionEventCreatorData | undefined
): string => {
  switch (createdBy) {
    case SubscriptionEventCreatorEnum.AGENT: {
      if (!creatorData || (!creatorData?.email && !creatorData?.firstName && !creatorData.lastName)) {
        return "Admin";
      }

      const name = `${creatorData.firstName ?? ""}${creatorData.lastName ? ` ${creatorData.lastName}` : ""}`;
      const email = creatorData.email ? ` (${creatorData.email})` : "";
      return `${name}${email}`;
    }

    case SubscriptionEventCreatorEnum.CUSTOMER: {
      return "Customer";
    }
    case SubscriptionEventCreatorEnum.SEQUENTIAL: {
      return "Sequential flow";
    }
    case SubscriptionEventCreatorEnum.SCRIPT: {
      return "Subscription Program";
    }
    case SubscriptionEventCreatorEnum.SMARTRR: {
      return "Smartrr";
    }
    case SubscriptionEventCreatorEnum.API: {
      return "Api";
    }
    default: {
      const exhaustiveCheck: never = createdBy;
      return exhaustiveCheck;
    }
  }
};

export const formatProduct = (
  vntToPurchasableMap: MappedPurchasableVariant,
  productName?: string,
  variantName?: string,
  variantId?: string
): string => {
  const purchasable = vntToPurchasableMap[variantId ?? ""];
  if (productName && variantName) {
    if (variantName === DEFAULT_VARIANT_TITLE) {
      return `${productName}`;
    }
    return `${productName} - ${variantName}`;
  } else if (purchasable?.vnts?.length) {
    const vnt = purchasable.vnts.find(
      vnt => vnt.id === variantId && vnt.purchasableVariantName !== DEFAULT_VARIANT_TITLE
    );
    if (vnt) {
      return `${purchasable.purchasableName} - ${vnt.purchasableVariantName}`;
    }
    return `${purchasable.purchasableName}`;
  }
  return productName ?? variantName ?? "Unknown Product";
};

export const formatEventDescription = (
  event: ISubscriptionEventWithPurchaseStateInformation | ShopifyEvent,
  vntToPurchasableMap: MappedPurchasableVariant
): string => {
  if (isShopifyComment(event)) {
    return event.message;
  }

  const eventData = event.eventData ?? {};
  const createdBy = event.createdBy;
  const creatorData = event.creatorData;
  const creator = formatCreator(createdBy as SubscriptionEventCreatorEnum, creatorData);

  let orderDate;
  if (
    eventData.orderDate ||
    eventData.removedLineItem?.orderDate ||
    eventData.addedLineItem?.orderDate ||
    eventData.redeemedItemDetails?.orderDate
  ) {
    [orderDate] = formatDateTime(
      eventData?.orderDate ||
        eventData?.removedLineItem?.orderDate ||
        eventData?.addedLineItem?.orderDate ||
        eventData.redeemedItemDetails?.orderDate
    );
  }

  const handleRedemption = (eventData: any, creator: string, orderDate: string): string => {
    const pointsName = eventData.redeemedItemDetails?.pointsName;
    const previousPoints = eventData.redeemedItemDetails?.previousPoints;
    const finalPoints = eventData.redeemedItemDetails?.finalPoints;

    const redemptionDetails = `${creator} redeemed ${eventData.redeemedItemDetails?.name} for ${
      eventData.redeemedItemDetails?.pointsAmount
    } ${pointsName}, applied to order scheduled for ${orderDate}.`;

    const pointsSummary =
      previousPoints !== undefined && finalPoints !== undefined
        ? ` Starting ${pointsName} total was ${previousPoints}. Final ${pointsName} total is ${finalPoints}.`
        : "";

    return `${redemptionDetails}${pointsSummary}`;
  };

  const eventType = event.eventType as SubscriptionEventTypeEnum;
  // eslint-disable-next-line sonarjs/max-switch-cases
  switch (eventType) {
    case SubscriptionEventTypeEnum.CHANGE_ORDER_DATE: {
      if (!orderDate) {
        return `${creator} changed next order date`;
      }
      return `${creator} changed next order date to ${orderDate}`;
    }
    case SubscriptionEventTypeEnum.CHANGE_SELLING_PLAN: {
      if (!eventData.sellingPlanId) {
        return `${creator} changed subscription program`;
      }
      return `${creator} changed subscription program to: ${eventData.sellingPlanId}`;
    }
    case SubscriptionEventTypeEnum.PAUSE: {
      return `${creator} paused subscription`;
    }
    case SubscriptionEventTypeEnum.CANCEL: {
      return `${creator} canceled subscription`;
    }
    case SubscriptionEventTypeEnum.CANCEL_PREPAID: {
      return `${creator} canceled all remaining prepaid orders`;
    }
    case SubscriptionEventTypeEnum.EXPIRED: {
      return `Subscription reached end of cycle`;
    }
    case SubscriptionEventTypeEnum.ACTIVATE: {
      return `${creator} activated subscription`;
    }
    case SubscriptionEventTypeEnum.FAILED_DISCOUNT: {
      if (!eventData.discountCode) {
        return `${creator} unsuccessfully tried to add a discount code`;
      }
      return `${creator} unsuccessfully tried to add discount code: ${eventData.discountCode}`;
    }
    case SubscriptionEventTypeEnum.SUCCESSFUL_DISCOUNT: {
      if (!eventData.discountCode) {
        return `${creator} successfully added a discount code`;
      }
      return `${creator} successfully added discount code: ${eventData.discountCode}`;
    }
    case SubscriptionEventTypeEnum.SUCCESSFUL_BILLING_ATTEMPT: {
      return `Successful billing attempt`;
    }
    case SubscriptionEventTypeEnum.FAILED_BILLING_ATTEMPT: {
      return `Failed billing attempt`;
    }
    case SubscriptionEventTypeEnum.SKIP: {
      return `${creator} skipped order for ${orderDate}`;
    }
    case SubscriptionEventTypeEnum.UNSKIP: {
      return `${creator} unskipped order for ${orderDate}`;
    }
    case SubscriptionEventTypeEnum.GIFT: {
      if (!orderDate) {
        return `${creator} gifted order`;
      }
      return `${creator} gifted order for ${orderDate}`;
    }
    case SubscriptionEventTypeEnum.UNDO_GIFT: {
      if (!orderDate) {
        return `${creator} called off gifting order`;
      }
      return `${creator} called off gifting order for ${orderDate}`;
    }
    case SubscriptionEventTypeEnum.REMOVE_DISCOUNT: {
      return `${creator} removed discount`;
    }
    case SubscriptionEventTypeEnum.REMOVE_LINE_ITEM: {
      const removedProductName = formatProduct(
        vntToPurchasableMap,
        eventData.removedLineItem?.productName,
        eventData.removedLineItem?.variantName
      );
      if (orderDate) {
        return `${creator} removed ${removedProductName} from order due on ${orderDate}`;
      }
      return `${creator} removed ${removedProductName} from subscription`;
    }

    case SubscriptionEventTypeEnum.ADD_LINE_ITEM: {
      const addedProductName = formatProduct(
        vntToPurchasableMap,
        eventData.addedLineItem?.productName,
        eventData.addedLineItem?.variantName,
        eventData.addedLineItem?.variantId
      );
      if (orderDate) {
        return `${creator} added ${addedProductName} as one-time add-on to order due on ${orderDate}`;
      }
      return `${creator} added ${addedProductName} to subscription`;
    }

    case SubscriptionEventTypeEnum.CHANGE_SHIPPING_PRICE: {
      const currency = event.currency ?? "USD";
      if (!eventData.previousShippingPrice || !eventData.updatedShippingPrice) {
        return `${creator} changed shipping price`;
      }

      const formattedPreviousShippingPrice = formatMoneyWithoutPrecisionConversion(
        eventData.previousShippingPrice,
        currency
      );
      const formattedUpdatedShippingPrice = formatMoneyWithoutPrecisionConversion(
        eventData.updatedShippingPrice,
        currency
      );

      return `${creator} changed shipping price from ${formattedPreviousShippingPrice} to ${formattedUpdatedShippingPrice}`;
    }
    case SubscriptionEventTypeEnum.SWAP_LINE_ITEM: {
      const swapRemovedProductName = formatProduct(
        vntToPurchasableMap,
        eventData.removedLineItem?.productName,
        eventData.removedLineItem?.variantName
      );
      const swapAddedProductName = formatProduct(
        vntToPurchasableMap,
        eventData.addedLineItem?.productName,
        eventData.addedLineItem?.variantName,
        eventData.addedLineItem?.variantId
      );
      return `${creator} swapped ${swapRemovedProductName} with quantity ${eventData.removedLineItem?.quantity} for ${swapAddedProductName} with quantity ${eventData.addedLineItem?.quantity}`;
    }
    case SubscriptionEventTypeEnum.UPDATE_LINE_ITEM: {
      const updatedQuantity = eventData.updatedLineItem?.updatedQuantity;
      const updatedPrice = eventData.updatedLineItem?.updatedPrice;
      const updatedVariantName = eventData.updatedLineItem?.updatedVariantName;
      const previousQuantity = eventData.updatedLineItem?.previousQuantity;
      const originalProductName = formatProduct(
        vntToPurchasableMap,
        eventData.updatedLineItem?.productName,
        eventData.updatedLineItem?.variantName,
        eventData.updatedLineItem?.variantId
      );
      const updatedProductName = formatProduct(
        vntToPurchasableMap,
        eventData.updatedLineItem?.productName,
        eventData.updatedLineItem?.updatedVariantName
      );
      if (updatedQuantity === undefined) {
        // This is temporary fix for displaying LINE UPDATE EVENT when variant is changed from customer portal
        return `${creator} updated ${originalProductName} to another product variant`;
      } else if (updatedPrice !== undefined) {
        return `${creator} updated ${updatedProductName} with quantity ${updatedQuantity} and price $${updatedPrice}`;
      } else if (updatedPrice === undefined && updatedVariantName && updatedQuantity !== previousQuantity) {
        return `${creator} updated ${originalProductName} with quantity ${previousQuantity} to ${updatedProductName} with quantity ${updatedQuantity}`;
      } else if (updatedPrice === undefined && updatedVariantName && updatedQuantity === previousQuantity) {
        return `${creator} updated ${originalProductName} to ${updatedProductName} `;
      }
      return `${creator} updated ${updatedProductName} with quantity ${updatedQuantity}`;
    }

    case SubscriptionEventTypeEnum.CHANGE_ADDRESS: {
      const address = eventData.address as {
        deliveryMethod: {
          shipping: { address: IMailingAddress };
        };
      };
      const purchaseState: IPurchaseState = eventData.purchaseState!;
      if (!address) {
        return `${creator} changed default address`;
      }
      const street1 = address.deliveryMethod.shipping.address.address1;
      const street2 = address.deliveryMethod.shipping.address.address2;
      const { city } = address.deliveryMethod.shipping.address;
      const province = address.deliveryMethod.shipping.address.province ?? "";
      const { zip } = address.deliveryMethod.shipping.address;
      const { country } = address.deliveryMethod.shipping.address;
      return `${creator} changed address to ${street1}${
        street2 ? ` ${street2}` : ""
      }, ${city}, ${province} ${zip} ${country} ${
        purchaseState?.shopifyId ? `for subscription #${shopifyGidToNumber(purchaseState.shopifyId)}` : ""
      }`;
    }
    case SubscriptionEventTypeEnum.DELETE_ADDRESS: {
      const delAddress: any = eventData.address;
      return `${creator} deleted address ${delAddress.shopifyId}`;
    }
    case SubscriptionEventTypeEnum.UPDATE_ADDRESS: {
      const updatedAddress: any = eventData.address;
      if (!updatedAddress) {
        return `${creator} updated an address`;
      }
      const updatedStreet1 = updatedAddress.address1;
      const updatedStreet2 = updatedAddress.address2;
      const updatedCity = updatedAddress.city;
      const updatedProvince = updatedAddress.province ?? "";
      const updatedZip = updatedAddress.zip;
      const updatedCountry = updatedAddress.country;
      return `${creator} updated address ${updatedAddress.shopifyId} to be ${updatedStreet1}${
        updatedStreet2 ? ` ${updatedStreet2}` : ""
      }, ${updatedCity}, ${updatedProvince} ${updatedZip} ${updatedCountry}`;
    }
    case SubscriptionEventTypeEnum.ADD_ADDRESS: {
      const addedAddress: any = eventData.address;
      if (!addedAddress) {
        return `${creator} added an address`;
      }
      const addedStreet1 = addedAddress.address1;
      const addedStreet2 = addedAddress.address2;
      const addedCity = addedAddress.city;
      const addedProvince = addedAddress.province;
      const addedZip = addedAddress.zip;
      const addedCountry = addedAddress.country;
      return `${creator} added address: ${addedStreet1} ${
        addedStreet2 ? ` ${addedStreet2}` : ""
      }, ${addedCity}, ${addedProvince} ${addedZip} ${addedCountry}`;
    }
    case SubscriptionEventTypeEnum.CREATE_BILL: {
      const billingInfo =
        eventData.billEventType === "SUCCESS"
          ? `successfully created bill #${eventData.billId}`
          : `failed to create bill, error: ${eventData.billEventType}`;
      return `${creator} ${billingInfo}`;
    }
    case SubscriptionEventTypeEnum.UPDATE_EXTERNAL_SUBSCRIPTION_DATES: {
      const { updatedExternalSubcriptionDates } = eventData;
      const previousExternalSubscriptionCreatedDate =
        updatedExternalSubcriptionDates?.previousExternalSubscriptionCreatedDate;
      const externalSubscriptionCreatedDate = updatedExternalSubcriptionDates?.externalSubscriptionCreatedDate;
      const previousExternalSubscriptionCancelledAt =
        updatedExternalSubcriptionDates?.previousExternalSubscriptionCancelledAt;
      const externalSubscriptionCancelledAt = updatedExternalSubcriptionDates?.externalSubscriptionCancelledAt;
      return `${creator} changed external subscription created date from ${previousExternalSubscriptionCreatedDate} to ${externalSubscriptionCreatedDate},
        and changed external subscription cancelled date from ${previousExternalSubscriptionCancelledAt} to ${externalSubscriptionCancelledAt}`;
    }
    case SubscriptionEventTypeEnum.FAILED_CHANGE_ORDER_DATE: {
      return `${creator} failed trying to change order date`;
    }
    case SubscriptionEventTypeEnum.FAILED_CHANGE_SELLING_PLAN: {
      return `${creator} failed trying to change subscription plan`;
    }
    case SubscriptionEventTypeEnum.FAILED_PAUSE: {
      return `${creator} failed trying to pause subscription`;
    }
    case SubscriptionEventTypeEnum.FAILED_CANCEL: {
      return `${creator} failed trying to cancel subscription`;
    }
    case SubscriptionEventTypeEnum.FAILED_ACTIVATE: {
      return `${creator} failed trying to activate subscription`;
    }
    case SubscriptionEventTypeEnum.FAILED_SKIP: {
      return `${creator} failed trying to skip subscription`;
    }
    case SubscriptionEventTypeEnum.FAILED_UNSKIP: {
      return `${creator} failed trying to unskip subscription`;
    }
    case SubscriptionEventTypeEnum.FAILED_GIFT: {
      return `${creator} failed trying to gift subscription`;
    }
    case SubscriptionEventTypeEnum.FAILED_UNDO_GIFT: {
      return `${creator} failed trying to undo gift`;
    }
    case SubscriptionEventTypeEnum.FAILED_ADD_ADDRESS: {
      return `${creator} failed trying to add an address`;
    }
    case SubscriptionEventTypeEnum.FAILED_UPDATE_ADDRESS: {
      return `${creator} failed trying to update an address`;
    }
    case SubscriptionEventTypeEnum.FAILED_DELETE_ADDRESS: {
      return `${creator} failed trying to delete an address`;
    }
    case SubscriptionEventTypeEnum.FAILED_CHANGE_ADDRESS: {
      return `${creator} failed trying to change an address`;
    }
    case SubscriptionEventTypeEnum.FAILED_REMOVE_DISCOUNT: {
      return `${creator} failed trying to remove a discount code`;
    }
    case SubscriptionEventTypeEnum.FAILED_REMOVE_LINE_ITEM: {
      return `${creator} failed trying to remove a line item`;
    }
    case SubscriptionEventTypeEnum.FAILED_UPDATE_LINE_ITEM: {
      return `${creator} failed trying to update a line item`;
    }
    case SubscriptionEventTypeEnum.FAILED_ADD_LINE_ITEM: {
      return `${creator} failed trying to add a line item`;
    }
    case SubscriptionEventTypeEnum.FAILED_CHANGE_SHIPPING_PRICE: {
      return `${creator} failed trying to change shipping price`;
    }
    case SubscriptionEventTypeEnum.FAILED_SWAP_LINE_ITEM: {
      return `${creator} failed trying to swap a line item`;
    }
    case SubscriptionEventTypeEnum.OOS_AUTOMATIC_PAUSE: {
      if (!eventData.removedLineItem) {
        return `Subscription automatically paused due to unavailable item`;
      }
      const { productName, variantName } = eventData.removedLineItem;
      return `Subscription automatically paused due to out of stock item ${productName}${
        variantName === DEFAULT_VARIANT_TITLE ? "" : ` - ${variantName}`
      }`;
    }

    case SubscriptionEventTypeEnum.UNAVAILABLE_AUTOMATIC_PAUSE: {
      if (!eventData.removedLineItem) {
        return `Subscription automatically paused due to unavailable item`;
      }
      const { productName, variantName } = eventData.removedLineItem;
      return `Subscription automatically paused due to unavailable item ${productName}${
        variantName === DEFAULT_VARIANT_TITLE ? "" : ` - ${variantName}`
      }`;
    }

    case SubscriptionEventTypeEnum.OOS_AUTOMATIC_SKIP: {
      return `Order for ${orderDate} was skipped because one item was out of stock`;
    }
    case SubscriptionEventTypeEnum.SUCCESSFUL_REFERRAL: {
      if (!eventData.rewardDetails?.rewardPoints || !eventData.rewardDetails?.refereeName) {
        return `Customer successfully referred a user, successful referral email sent.`;
      }
      return `Successful referral email sent. Customer earned ${eventData.rewardDetails?.rewardPoints} ${eventData.rewardDetails?.unitName} by referring ${eventData.rewardDetails?.refereeName} from ${eventData.rewardDetails?.eventName} program.`;
    }
    case SubscriptionEventTypeEnum.REWARDS_POINTS_EARNED: {
      return `Customer earned ${eventData.rewardDetails?.rewardPoints} points on the event ${eventData.rewardDetails?.eventName}`;
    }
    case SubscriptionEventTypeEnum.BILL_CREATION_FAILED: {
      return `Subscription paused due billing failure`;
    }
    case SubscriptionEventTypeEnum.REWARDS_POINTS_CORRECTION: {
      if (eventData.rewardDetails?.rewardPoints! > 0) {
        return `Customer earned ${eventData.rewardDetails?.rewardPoints} points on the event ${eventData.rewardDetails?.eventName} due to order ${eventData.rewardDetails?.orderId}`;
      }
      return `Customer had a correction of ${eventData.rewardDetails?.rewardPoints} points on the event ${eventData.rewardDetails?.eventName} due to order ${eventData.rewardDetails?.orderId} refunding`;
    }
    case SubscriptionEventTypeEnum.REWARDS_REDEEMED_INCENTIVE:
    case SubscriptionEventTypeEnum.REWARDS_REDEEMED_PRODUCT: {
      return handleRedemption(eventData, creator, orderDate);
    }

    case SubscriptionEventTypeEnum.FAILED_UPDATE_EXTERNAL_SUBSCRIPTION_DATES: {
      return `${creator} failed to update external subscription dates`;
    }
    case SubscriptionEventTypeEnum.NOTIFICATION_SUBS_CREATED_NEW: {
      return `Subscription created email sent to new customer`;
    }
    case SubscriptionEventTypeEnum.NOTIFICATION_SUBS_CREATED_EXISTING: {
      return `Subscription created email sent to customer`;
    }
    case SubscriptionEventTypeEnum.NOTIFICATION_PAYMENT_FAILED: {
      return `Payment failed email sent to customer`;
    }
    case SubscriptionEventTypeEnum.NOTIFICATION_SUBSCRIPTION_CANCELED: {
      return `Subscription canceled email sent to customer`;
    }
    case SubscriptionEventTypeEnum.NOTIFICATION_SUBSCRIPTION_PAUSED: {
      return `Subscription paused email sent to customer`;
    }
    case SubscriptionEventTypeEnum.NOTIFICATION_SUBSCRIPTION_ACTIVATED: {
      return `Subscription activated email sent to customer`;
    }
    case SubscriptionEventTypeEnum.NOTIFICATION_UPCOMING_ORDER: {
      return `Upcoming subscription email sent to customer`;
    }
    case SubscriptionEventTypeEnum.NOTIFICATION_PAYMENT_EXPIRATION: {
      return `Payment expiration email sent to customer`;
    }
    case SubscriptionEventTypeEnum.NOTIFICATION_ITEM_OUT_OF_STOCK: {
      return `Item out of stock or deleted email sent to customer`;
    }
    case SubscriptionEventTypeEnum.NOTIFICATION_GIFT_CREATED: {
      return `Gift created email sent to customer`;
    }
    case SubscriptionEventTypeEnum.NOTIFICATION_GIFT_SHIPPING: {
      return `Gift shipping email sent to customer`;
    }
    case SubscriptionEventTypeEnum.AUTO_RESUMED: {
      if (eventData.autoUnpauseReason) {
        return `Subscription was auto resumed: ${eventData.autoUnpauseReason}`;
      }
      return "Subscription was auto resumed";
    }
    case SubscriptionEventTypeEnum.ADMIN_LOGGEDIN_AS_CUSTOMER:
    case SubscriptionEventTypeEnum.SUPER_USER_LOGGEDIN_AS_CUSTOMER: {
      if (!eventData.loginData || createdBy === "AGENT") {
        return `${creator} logged in as customer`;
      }
      const { firstName, lastName, email } = eventData.loginData;
      return `${firstName ?? ""} ${lastName ?? ""} (${email ?? ""}) logged in as customer`;
    }
    case SubscriptionEventTypeEnum.CHANGE_EMAIL: {
      let str = `${creator} updated email`;
      if (eventData.updateCustomerInfo?.oldEmail) {
        str += ` from ${eventData.updateCustomerInfo?.oldEmail}`;
      }
      if (eventData.updateCustomerInfo?.currentEmail) {
        str += ` to ${eventData.updateCustomerInfo?.currentEmail}`;
      }
      return str;
    }
    case SubscriptionEventTypeEnum.SEQUENTIAL_SWAP: {
      const sequentialSwapRemovedProductName = formatProduct(
        vntToPurchasableMap,
        eventData.removedLineItem?.productName,
        eventData.removedLineItem?.variantName
      );
      const sequentialSwapAddedProductName = formatProduct(
        vntToPurchasableMap,
        eventData.addedLineItem?.productName,
        eventData.addedLineItem?.variantName,
        eventData.addedLineItem?.variantId
      );
      return `${creator} swapped ${sequentialSwapRemovedProductName} with quantity ${eventData.removedLineItem?.quantity} for ${sequentialSwapAddedProductName} with quantity ${eventData.addedLineItem?.quantity}`;
    }
    case SubscriptionEventTypeEnum.SEQUENTIAL_REMOVE_PRODUCT: {
      const sequentialRemovedProductName = formatProduct(
        vntToPurchasableMap,
        eventData.removedLineItem?.productName,
        eventData.removedLineItem?.variantName
      );
      return `${creator} removed ${sequentialRemovedProductName} with quantity ${eventData.removedLineItem?.quantity}`;
    }
    case SubscriptionEventTypeEnum.SEQUENTIAL_TERMINATE_CONTRACT: {
      return `${creator} terminated contract due to reaching end of sequence and having no line items`;
    }
    case SubscriptionEventTypeEnum.SEQUENTIAL_RESTART_SEQUENCE: {
      return `${creator} restarted the sequence from order #${eventData.restartSequence?.restartFromSequenceNumber}`;
    }
    default: {
      return eventType;
    }
  }
};
