import { IOrganization } from "@smartrr/shared/entities/Organization";
import { shopifyGidToNumber } from "@smartrr/shared/utils/ensureShopifyGid";
import { frontEndTabOpen } from "@smartrr/shared/utils/locationUtils";

export const viewSubscriptionsByCustomer = (customerShopifyId: string, activeOrg: IOrganization | null) => {
  if (!activeOrg?.myShopifyDomain) {
    return;
  }
  frontEndTabOpen(
    `https://${activeOrg.myShopifyDomain}/admin/orders?tag=Subscription&customer_id=${shopifyGidToNumber(
      customerShopifyId
    )}`
  );
};
